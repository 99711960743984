RBEloginUx.login = {};
RBEloginUx.login.interval = null;
var ABE_Service_URL = "https://login.morphient.com";// "http://localhost:9080"; //"https://login.morphient.com"; // New Server URL

/**
 * This function performs login for Amazon/FB using ABEService
 * 
 * @param successCb
 * @param errorCb
 */
RBEloginUx.login.doLogin = function(successCb, errorCb) {
	var win = window.open(ABE_Service_URL + "/auth/" +RBEloginUx.autherizer + "?loginName=" + RBEloginUx.abeLoginName,
			RBEloginUx.windowTitle,
			"toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=985, height=520, top=" + (screen.height / 4) + ", left=" + (screen.width / 4));
	function intervalFn() {
	    try {
            if (win) {
                win.postMessage('getContent', ABE_Service_URL);
            } else {
                clearInterval(RBEloginUx.login.getAuthInterval);
            }
	    } catch(e) {
	        alert("Something went wrong while login, please contact your administrator...");
            console.log("Got error:", e);
	    }
	}
	RBEloginUx.login.getAuthInterval = setInterval(intervalFn, 1000);
	RBEloginUx.login.loginSuccessCb = successCb;
	RBEloginUx.login.loginErrorCb = errorCb;
};

/**
 * 
 */
RBEloginUx.login.registerMessageEvent = function() {
    try {
        if (window.addEventListener)
            window.addEventListener('message', onPingBackMessage, false);
        else if (window.attachEvent)
            window.attachEvent('message', onPingBackMessage, false);
    } catch(e) {
        alert("Something went wrong while login, please contact your administrator...");
        console.log("Got error:", e);
    }

	function onPingBackMessage(event) {
	    try {
            if (event.origin == ABE_Service_URL) {
                clearInterval(RBEloginUx.login.getAuthInterval);
                var response = event.data;
                if (response != null && response != undefined && !response.error) {
                    if (typeof response.error === 'undefined') {// Success so call function to process the form
                        console.log('User logged in using Auth Provider.');
                        RBEloginUx.login.loginSuccessCb(response);
                    }
                } else {
                    console.log('There was a problem logging you in: %j', response);
                    RBEloginUx.login.loginErrorCb(response.error);
                }
            }
	    } catch(e) {
	        alert("Something went wrong while login, please contact your administrator...");
            console.log("Got error:", e);
        }
	}
};

RBEloginUx.login.registerMessageEvent();